// init foundarion 
$(document).foundation();

//IE old version tip
var userAgent = window.navigator.userAgent;
var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
if (userAgent.indexOf("MSIE 7.0") > 0 || userAgent.indexOf("MSIE 8.0") > 0 || userAgent.indexOf("MSIE 9.0") > 0 || navigator.appVersion.indexOf("MSIE 10") !== -1) {
	// 上線後，請後端更改為客戶網址的browser.html路徑。
	// 以下為海棠測試機的browser.html檔案路徑。
	var url = "https://green-n-safe.com/browser.html";
	setTimeout(
		function () {
			$(location).attr('href', url);
		}, 0);
}
// doc ready
(function ($, window, document) {
	// Window 相關設定
	var w = window,
		win = $(window),
		ww,
		wh,
		ws,
		wstemp,
		docH = document.body.scrollHeight;

	// 取得Window設定值
	var windowSetting = function () {
		ww = win.innerWidth();
		wh = win.innerHeight();
		ws = win.scrollTop();
		wstemp = ws;
		docH = document.body.scrollHeight;
	}
	windowSetting();
	// ----------------------------------- Window 相關監測
	// window on scroll use javascript
	// Reference: https://stackoverflow.com/a/10915048
	// http://dev.w3.org/2006/webapi/DOM-Level-3-Events/html/DOM3-Events.html#event-type-scroll
	function onScrollEventHandler(ev) { 
		detectWinScroll();
	}
	function onResizeEventHandler(ev) {
		windowSetting();
	}
	if (w.addEventListener) {
		w.addEventListener('scroll', onScrollEventHandler, false);
		w.addEventListener('resize', onResizeEventHandler, false);
		// w.addEventListener('load', onLoadEventHandler, false);   
	} else if (w.attachEvent) {
		w.attachEvent('onscroll', onScrollEventHandler);
		w.attachEvent('onresize', onScrollEventHandler);
		w.attachEvent('load', onLoadEventHandler);
    }

	// =================================================
	var isIE = document.documentElement.browser === 'IE';
	var $Device = ['ie', 'iphone', 'ipad', 'android', 'windows_phone'];	
	// header 判斷
	var pcflag = 0;
	var mbflag = 0;
	// 綠色chaper Menu 變數設定
    var headerH = 0;
	var pageChaperH = 0;

	// -----------------------------------
	// Loader 
	// var loaderRemove = function(){
	// 	$('#loader').fadeOut('1000',function(){
	// 		$(this).remove();
	// 	});
	// }

	// lazysizes bg load
	document.addEventListener('lazybeforeunveil', function (e) {
		var bg = e.target.getAttribute('data-bg');
        if (bg) {
			e.target.style.backgroundImage =  bg;
        }
	});

	// window load 
	$(window).on("load", function (e) {
		headerHamberger();
		headerMenuSlide();
		footerHamberger();
		mobileFullHeight();

		// 廣告區塊
		if($('[data-reveal]').length) {
			$('[data-reveal]').removeClass('is-hide');
		}
		
		// 廣告modal，透過 cookie 來設置
		checkCookieAccept();

		// 頁面滾動時的動畫判斷
		animateJS();

		// 響應式替換圖片（單張）
		// imgBreakpointCheck('.event-banner');

		// 綠色chaper Menu 手機版位置
		setPageChapterMenuPos();
		
		setTimeout(function(){
            scrollFixedMenu('.js-fixed-top_nav', 'top');
            scrollFixedMenu('.js-fixed-bottom_nav', 'bottom', 'footer');
            // if has window.location.hash
            // onloadSetAnchorPos();
		}, 300);

		// check lazyload bg
		checkIfDataBgIsNotLoad();
		
	}).on("resize", function(){
		windowSetting();
		mobileFullHeight();
		footerHambergerResize();

		if(ww >= 768) {
			elRemoveStyle('.ft-top__link-list');
		}

		if(ww >= 1200) {
			var headerId = '#header';
			elRemoveStyle('#header .hd-bottom');
			// elRemoveStyle('#header .hd-bottom__menu-pc-sec');
			// elRemoveStyle('#header .hd-bottom__menu-mb-sec');
			elRemoveActive('#header .js-hamburger-menu');
			elRemoveActive('#header .hd-bottom');
			elRemoveActive('#header .js-menu-active:not([data-action="click"])');
			elRemoveActive('#header .hd-bottom__menu-pc-sec:not([data-action="click"])');

			// $('.reveal-overlay')
			$('html').removeClass('stop-scrolling');
			$('html').attr('data-pos', document.documentElement.scrollTop);
		}

		// 頁面滾動時的動畫判斷
		animateJS();
		headerMenuSlide();

		// 響應式替換圖片（單張）
		// imgBreakpointCheck('.event-banner');

		// 綠色chaper Menu 手機版位置
		setPageChapterMenuPos();
		scrollFixedMenu('.js-fixed-top_nav', 'top');
		
		// 價格對齊
		pdPriceAlign();

	}).on("scroll", function(){
		// 頁面滾動時的動畫判斷
		animateJS();

		// 綠色chaper Menu 手機版位置
		scrollFixedMenu('.js-fixed-top_nav', 'top');
		scrollFixedMenu('.js-fixed-bottom_nav', 'bottom', 'footer');

		// check lazyload bg
		checkIfDataBgIsNotLoad();
	});

	$(document).ready(function(){

		// 價格對齊
		pdPriceAlign();

		// OPT邏輯
		inputKey();

		// headerMenuSlide();
		clickScrollTop('.js-anchor-top'); // scroll top

		 // page smooth scroll
		if ($Device.indexOf(_uac.browser) == -1) {
            smoothScroll();
		} 
		
		// Video Popup (配合LazyLoad)
		// buildVideoBox();


		// 調整 modal 內容為垂直置中
		var revealActiveAnchor = null;

		$('[data-open]').on('click', function () {
			revealActiveAnchor = this;
		});

		// 廣告 modal
		$(document)
			.on('open.zf.reveal', '[data-reveal]', function () {
				var $html = $('html');
				var $this = $(this);
				
				var nowH = $html.css('top').match(/\d+/);
				$html.attr('data-pos', nowH);
				$html.attr('data-scroll', false);
			})
			.on('closed.zf.reveal', '[data-reveal]', function () {
				if (revealActiveAnchor != null) {
					revealActiveAnchor.focus();
					revealActiveAnchor = null;
				}
				$('html').attr('data-scroll', true);
			});
		
		$(document).on('click', '.event-banner .event-banner__close', function(){
			$('.event-banner').slideUp(350);
		});

		// sidebar mobile 
		if($('.js-sidenav-dropdown').length) {
			var elCls = '.js-sidenav-dropdown';

			$(elCls).on('click', function(){
				$(this).toggleClass('is-open');
			});

			$('.article_aside .sidenav_list_item_link.js-foodbox-anchor').on('click', function(){
				$('.js-sidenav-dropdown.is-open').removeClass('is-open');

				var thisTitle = $(this).find('.sidenav_list_item_title').html();
				$('.sidenav_dropdown_link_title').html(thisTitle)
			});
		}

		// 加入最愛
		setSwiperAddToWishActive();

	});

	$(document).on('opened.zf.offcanvas', '[data-off-canvas]', function () {
		var thisId = $(this).attr('id');
		// open cart
		if(thisId == "modal__cart-sidenav") {
			triggeHtmlStop(true);
		}
	});
	  
	$(document).on('closed.zf.offcanvas', '[data-off-canvas]', function () {
		var thisId = $(this).attr('id');
		// open cart
		if(thisId == "modal__cart-sidenav") {
			triggeHtmlStop(false);
		}
  	});

	$(document).on('click', '#modal__cart-sidenav .card__box-remove', function(){
		$(this).parents('.card__box').remove();
	});

	$(document).on('click', '.js-toggle-password', function(){
		var $input = $(this).parent('.form_box').find('.form_input');
		
		if($(this).hasClass('is-active')) {
			$(this).removeClass('is-active');
			$input.attr('type', 'password');
		} else {
			$(this).addClass('is-active');
			$input.attr('type', 'text');
		}

	});

    function modalCenter($target){
        // var wh = $(window).innerHeight();
        // var $self = $target;
        // var top = (wh - $self.innerHeight()) / 2;
        // if(top < 0) top = 0;
		// $self.css('top', top);

		$target.removeClass('is-hide');
    }

// -----------------------------------
// 
// -----------------------------------

	function elRemoveStyle(className){
		$(className).removeAttr('style');
	}

	function elRemoveActive(className){
		$(className).removeClass('active');
	}

	function footerHamberger(){
		$('.js-ft-menu-switch').on('click', function(){
			var $thisMenu = $(this).find('.ft-top__link-list');

			if(ww <= 768) {
				if($(this).hasClass('active')){
					$(this).removeClass('active');
					$thisMenu.stop().slideUp(400);
				} else {
					$(this).addClass('active');
					$thisMenu.stop().slideDown(400);
				}
			}
		});
	}

	function footerHambergerResize() {
		if(ww > 768) {
			$(".js-ft-menu-switch").removeClass("active");
		}
	}

	function headerHamberger(){
		$('.js-hamburger-menu').on('click', function(){
			var $menu = $('#header .hd-bottom');

			if($(this).hasClass('active')){
				$(this).removeClass('active');
				$menu.removeClass('active');
				$('header .js-menu-active:not([data-action="click"]):not([data-sec="menu-shop"])').removeClass('active');
				$('.hd-bottom__menu-mb-sec').removeClass('active');
				triggeHtmlStop(false);
			} else {
				$(this).addClass('active');
				$menu.addClass('active');
				$('header .js-menu-active[data-sec="menu-shop"]').addClass('active');
				triggeHtmlStop(true);
			}
		});
	}

	function headerMenuSlide(){
		var hoverSecName = null,
			leaveSecName = null;

		if(ww >= 1200){
			// resize 情況下只觸發一次
			if(!pcflag) {
				
				// 摧毀手機版事件
				$('.header .js-menu-active:not([data-sec="search"])').off('click');

				// 綁定新事件 - Hover
				$('.header .js-menu-active:not([data-action="click"])')
					.on('mouseenter', function(){
						hoverSecName = $(this).attr('data-sec');
			
						$('.js-menu-active:not([data-action="click"])').removeClass('active');
						$(this).addClass('active');
			
						if(hoverSecName){

							// 移除使用click開啟的物件
							$('.js-menu-active[data-action="click"]').removeClass('active');

							if(leaveSecName !== hoverSecName) {
								$('.hd-bottom__menu-pc-sec').removeClass('active');
								leaveSecName = hoverSecName;			
							}
							setTimeout(function(){
								$('.hd-bottom__menu-pc-sec[data-sec="'+hoverSecName+'"]').addClass('active');
							}, 150);
			
						} else {
							$('.hd-bottom__menu-pc-sec:not([data-action="click"])').removeClass('active');
						}
					})
					.on('mouseleave', function(){
						leaveSecName = $(this).attr('data-sec');
					});

				
				// 綁定新事件 - Click
				var clickTimer = null; // 因為 <label> 包在 <a> 內，導致觸發兩次 click，用setTimeout阻止
				$('.header .js-menu-active[data-action="click"]')
					.on('click', function() {
						var self = this,
							$self = $(self);

						clickTimer && clearTimeout(clickTimer);
						clickTimer = setTimeout(function() {
							var hoverSecName = $self.attr('data-sec');
							var targetSecPanel = $('.hd-bottom__menu-pc-sec[data-sec="'+hoverSecName+'"]');

							$self.toggleClass('active');

							$('.hd-bottom__menu-pc-sec').removeClass('active');

							if ($self.hasClass('active')) {
								// 開
								targetSecPanel.addClass('active');
							} else {
								// 關
								leaveSecName = hoverSecName;
								targetSecPanel.removeClass('active');
							}
						});

					})
					.on('mouseenter', function(){
						hoverSecName = $(this).attr('data-sec');
						$('.js-menu-active:not([data-action="click"])').removeClass('active');
					})
					.on('mouseleave', function(){
						leaveSecName = $(this).attr('data-sec');
					});

				$('.header .js-menu-active[data-action="click"]').each(function(index, item) {
					var hoverSecName = $(this).attr('data-sec');
					$('.hd-bottom__menu-pc-sec[data-sec="'+hoverSecName+'"]').attr('data-action', 'click');
				});
		
				// 滑鼠滑到上方 logo 區時
				$('.hd-top').on('mouseenter', function(){
					// 搜尋區塊只有在點擊，或是 hover 有下拉子選單的選單才要收起
					// 在這裡排除 search 區塊
					$('.hd-bottom__menu-pc-sec:not([data-action="click"])').removeClass('active');
					$('.js-menu-active:not([data-action="click"])').removeClass('active');
				});
				
				// 滑鼠離開 header 區塊時
				$('.header').on('mouseleave', function(){
					// 搜尋區塊只有在點擊，或是 hover 有下拉子選單的選單才要收起
					// 在這裡排除 search 區塊
					$('.hd-bottom__menu-pc-sec:not([data-action="click"])').removeClass('active');
					$('.js-menu-active:not([data-action="click"])').removeClass('active');
				});

				// 滑鼠滑到內容時
				$('.main-wrapper').on('mouseenter', function(){
					// 搜尋區塊只有在點擊，或是 hover 有下拉子選單的選單才要收起
					// 在這裡排除 search 區塊
					$('.hd-bottom__menu-pc-sec:not([data-action="click"])').removeClass('active');
					$('.js-menu-active:not([data-action="click"])').removeClass('active');
				});

				// 避免 input focus 狀態時收起
				var searchInputWrapper = $('.hd-bottom__menu-pc-sec[data-sec="search"]');
				$('.hd-search__input')
					.on('focus', function() {
						searchInputWrapper.addClass('can-not-close');
					})
					.on('focusout', function() {
						searchInputWrapper.removeClass('can-not-close');
						// searchInputWrapper.removeClass('active');
					});

				pcflag = 1;
				mbflag = 0;
			}

		} else {
			// resize 情況下只觸發一次
			if(!mbflag) {
				// 摧毀桌機版事件
				$('.header .js-menu-active').off('mouseleave mouseenter click');
				$('.hd-bottom').off('mouseleave');
				$('.hd-bottom__menu-pc-sec[data-sec="search"]').off('click');
				$('.hd-search__input').off('focus focusout');

				// 綁定新事件
				// 不要綁搜尋欄位的 click
				$('.header .js-menu-active:not([data-action="click"])').on('click', function(e){

					if($(this).parent('.dropdown-menu').length && $(this).attr('href')) {
						e.preventDefault();
					}
					
					if($(this).hasClass('active')){
						$(this).removeClass('active');
					} else {
						$(this).addClass('active');
					}
				});

				pcflag = 0;
				mbflag = 1;
			}
		}

	}

	// 偵測 scroll 方向／
	function detectWinScroll(){
		var $html = $('html');
		var $body = $('body');
		var scroll = $(window).scrollTop();

		var hH = ww >= 1200 ? document.querySelector('.header').offsetHeight : 65 ;
		var hSubMenuH = document.querySelector('.header .hd-bottom__menu-pc-sec.active') ?  document.querySelector('.header .hd-bottom__menu-pc-sec.active').offsetHeight : 0;

		// 當 modal 打開，scroll == ws，
		// 會讓 foodbox 下滑 header 收起時，打開 modal 會出現 header
		// ws = scroll == 0 ? ws - 1 : ws;

		if($html.attr('data-scroll') == 'false') {
			var dataPos = $html.attr('data-pos') ? $html.attr('data-pos') : $html.css('top').match(/\d+/);
			scroll = scroll == 0 ? dataPos : scroll;
		}
		
		if (+scroll > 0) {
			$body.addClass("scrolled");
		} else {
			$body.removeClass("scrolled");
		}

		if (+scroll > +hH - +hSubMenuH) {
			$body.addClass("scrollfold");
		} else {
			$body.removeClass("scrollfold");
		}
		
		if(+scroll !== +wstemp) {
			if (+scroll > +wstemp && +scroll !== 0) {
				$body.addClass("scrolldown").removeClass("scrollup");
			} else {
				$body.addClass("scrollup").removeClass("scrolldown");
			}
		}

		wstemp = scroll;
	}


// -----------------------------------
// 頁面滾動時的動畫判斷
// -----------------------------------
	function animateJS(){
		var $animation_elements = $('.js-animate');
		var window_height = win.height();
		var window_top_position = win.scrollTop();
		var window_bottom_position = (window_top_position + window_height);

		if($animation_elements.length){
			$.each($animation_elements, function() {
					var $element = $(this);
					var element_height = $element.outerHeight();
					var element_top_position = $element.offset().top;
					var element_bottom_position = (element_top_position + element_height);

					//check to see if this current container is within viewport
					if ((element_bottom_position >= window_top_position) && (element_top_position <= window_bottom_position)) {
							$element.addClass('is-animated');
					}else{
							// $element.removeClass('is-animated');
					}
			});
		}
	}

// -----------------------------------
// 響應式圖片替換
// -----------------------------------
	
	function imgBreakpointCheck(wrapClass) {
		var $wrap = document.querySelector(wrapClass);
		var $imgEl = $wrap.querySelector('img');
		var imageEl = {};

		imageEl.rwdImg = {
			s: $imgEl.dataset.srcSmall,
			m: $imgEl.dataset.srcDefault
		}

		if(imageEl.rwdImg === undefined) return false;

		var useSrc = ww >= 768 ? imageEl.rwdImg.m : imageEl.rwdImg.s;

		if(isIE) {
			$imgEl.style.backgroundImage = 'url(' + useSrc + ')'
		} else {
			$imgEl.src = useSrc
		}
	}


// -----------------------------------
// anchor click & smoothScroll
// -----------------------------------
	function smoothScroll() {
		$("html").easeScroll({
			frameRate: 18,
			animationTime: 600,
			stepSize: 120,
			pulseAlgorithm: !0,
			pulseScale: 8,
			pulseNormalize: 1,
			accelerationDelta: 20,
			accelerationMax: 1,
			keyboardSupport: !0,
			arrowScroll: 50
		});
	}

	function anchorClickScroll(){
		$('a[href*="#"]:not([href="#"])').click(function() {
			if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
				if (target.length) {
					clickScrollSetting(target.offset().top - 100)
					return false;
				}
			}
		});
	}

	function clickScrollTop($target) {
		if($($target).length) {
			$(document).on('click', $target, function(){
				clickScrollSetting(0);
			});
		}
	}

	function clickScrollSetting($scrollPos){
		$('html, body').animate({
			scrollTop: $scrollPos
		}, 550);
	}

// -----------------------------------
// Video Popup (配合LazyLoad)
// -----------------------------------
	// var videoBox = $('.card__box.vm-card__video');
	// var youTubeSetting = '?rel=0&modestbranding=1&autoplay=1&version=3&enablejsapi=1';

	// var videoIframe = null;

	// function buildVideoBox() {

	// 	var videoPopup = $('#modal__video');

	// 	if (videoBox.length === 0 || videoPopup.length === 0) return false;

	// 	var videoPopupPlayer = videoPopup.find('.video-player');
		
	// 	videoBox.each(function(){
	// 		var currentItem = $(this);
	// 		var youTubeUrl = currentItem.data('video');
	// 		var youTubeId = getYoutubeId(youTubeUrl);

	// 		// Get Title
	// 		// getYouTubeTitle(youTubeId, function(title) {
	// 		// 	currentItem.parent().find('.video-box__title').text(title)
	// 		// });

	// 		// 寫入Properties
	// 		this.videoId = youTubeId;

	// 		var videoImg = currentItem.find('.card__box-preview');
	// 		var videoPlayPopup = currentItem.find('[data-video-popup]');

	// 		var videoIframeSrc = 'https://www.youtube.com/embed/' + youTubeId + youTubeSetting;
	// 		var youTubeIframeString ='<iframe width="1080" height="608" class="video-player" src="'+videoIframeSrc+'" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>';

	// 		// Video Cover
	// 		var videoCoverData = currentItem.data('cover');
	// 		var videoCover = videoCoverData !== undefined ? videoCoverData : 'https://img.youtube.com/vi/'+ youTubeId +'/0.jpg';

	// 		if(videoImg.hasClass('lazy')) {
	// 			// 有LazyLoad
	// 			videoImg.attr('data-background', videoCover);
	// 			if(videoImg.parents('.swiper-slide') !== undefined) {
	// 				// 有Swiper的情況
	// 				videoImg.addClass('swiper-lazy');
	// 			} else {
	// 				// 一般情況
	// 				if(document.body.setLazy !== undefined) document.body.setLazy();
	// 			}
	// 		} else {
	// 			// 沒有Lazy
	// 			videoImg.css({
	// 				'background-image': 'url('+ videoCover +')'
	// 			});
	// 		}

	// 		// Click and load iframe
	// 		videoPlayPopup.on('click', function(){
	// 			videoPopupPlayer.append(youTubeIframeString);
				
	// 			videoIframe = videoPopupPlayer.get(0).querySelector('.video-player');
	// 			$(videoIframe).on('load', function(){
	// 				setTimeout(function(){
	// 					youTubePlay(videoIframe);
	// 				}, 1000);
	// 			});
	// 		});
	// 	});

	// 	videoPopup.on('closed.zf.reveal', function(){
	// 		$(this).find('iframe').remove();
	// 	})
	// }

	// // 讀取網址
	// // get-youtube-info.js: https://gist.github.com/ramonvictor/5629626
	// function getYoutubeId(url){
	// 	var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
	// 	var match = url.match(regExp);
	// 	if(match&&match[7].length==11){ 
	// 		return match[7];
	// 	}
	// 	return false;
	// }

	// // 影片播放
	// var domain = 'https://www.youtube.com';
	// function youTubePlay(el){
	// 	if(el !== null && el.contentWindow !== null) el.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', domain);
	// }
	// // 影片暫停
	// function youTubeStop(el){
	// 	if(el !== null && el.contentWindow !== null) el.contentWindow.postMessage( '{"event":"command","func":"pauseVideo","args":""}', domain);
	// }
	// // 影片重播
	// function youTubeReplay(el){
	// 	if(el !== null && el.contentWindow !== null) {
	// 		el.contentWindow.postMessage( '{"event":"command","func":"stopVideo","args":""}', domain);
	// 		el.contentWindow.postMessage( '{"event":"command","func":"playVideo","args":""}', domain);
	// 	}
	// }

// -----------------------------------
// 綠色chaper .js-fixed-chapterlist
// 請不要調整以下 function 內容，避免毀壞以使用到的頁面
// 有用到的頁面：食材內容介紹, 百寶箱組合, 百寶箱菜單
// -----------------------------------
    // chapter menu pos
    function setPageChapterMenuPos() {
		if($('.page_chapter').length) {
			pageChaperH = document.querySelector('.page_chapter').offsetHeight;

			$('.js-fixed-chapterlist .page_chapter_link').each(function(i, el){
				if($(el).hasClass('is-active')){
					chapterMenuPos('.js-fixed-chapterlist', i);
				}
			});
		}
    }

    function chapterMenuPos(target, eq){
        var thisEq = +eq;
        var menuMoveWw = 0;

        if(thisEq){
            for(var i = 0 ; i < thisEq ; i ++){
                menuMoveWw += $('.page_chapter_link').eq(i).outerWidth();
            }
        } else {
            menuMoveWw = 0
        }

        // $(target).stop().animate( { scrollLeft: menuMoveWw }, 500);
        $(target).stop().scrollLeft(menuMoveWw);
	}
	
	// dir 只有 top 跟 bottom
	function scrollFixedMenu(elCls, dir, stopPos){
        var $el = document.querySelector(elCls);
		var $html = $('html');

        if($el) {
            var elTop = 0;
            var elH = $el.offsetHeight;
            var hH = document.querySelector('.header').offsetHeight;
            // 減去子選單展開的高度
            var hSubMenuH = document.querySelector('.header .hd-bottom__menu-pc-sec.active') ?  document.querySelector('.header .hd-bottom__menu-pc-sec.active').offsetHeight : 0;

			headerH = hH - hSubMenuH;
			
            // Stop
            var startPos = 0;
            var endPos = 0;

            if(stopPos) {
                elTop = $el.offsetTop;
                endPos = document.querySelector('footer').offsetTop - window.innerHeight;
            } else {
                elTop = document.querySelector('.js-fixed-top_nav-anchor').offsetTop;
                // elTop = document.querySelector('.breadcrumbs').offsetHeight + document.querySelector('.breadcrumbs').offsetTop;
                endPos = docH;
            }
            
            // chaper menu
            startPos = elTop - headerH;

            if(dir == 'top') {
                if(ww < 768 ) {
                    startPos = $('body').hasClass('scrolldown') ? elTop : elTop - 65;
                }
            }

            // 當modal 開啟的時候，ws 會等於 0，導致所有以高度判斷的 js失效
            // 解決：打開 modal 時將 scrollTop 存到 data-pos 中。如果是0，ws 就取用 data-pos
            if($('html').attr('data-scroll') == 'false') {
			    var dataPos = $html.attr('data-pos') ? $html.attr('data-pos') : $html.css('top').match(/\d+/);
                wstemp = wstemp == 0 ? dataPos : wstemp;
            }

            if(+wstemp >= +startPos && +wstemp < +endPos) {
                $el.classList.add('js-sticky');

                if(dir == 'top') {
                    if(ww >= 1200) {
						if(headerH > 84)
							headerH = 84;
						$el.style.transform = "translateY("+headerH+"px)";
                    }
                    else {
                        $el.removeAttribute("style")
                    }
                }

            } else {
                $el.classList.remove('js-sticky');
                $el.removeAttribute("style")
            }
        }
    }


	//主打活動手機選單切換 
	$(document).on('click', '.drophead', function(){
		if ($(this).find('.show-event').hasClass("show-ul")) {
			$(this).find('.show-event').removeClass("show-ul");
			$(this).find('.hd-bottom__menu-icon').removeClass("is-open");
		}else{
			$(this).find('.show-event').addClass("show-ul");
			$(this).find('.hd-bottom__menu-icon').addClass('is-open');
		}
	});

	$(document).on('mouseleave', '.dropdown.menu', function(e){
		e.stopPropagation();
		$(this).find('.show-event').removeClass("show-ul");
		$(this).find('.hd-bottom__menu-icon').removeClass("is-open");

		onMouseOut(e);
	});

	function onMouseOut(event) {
			//this is the original element the event handler was assigned to
			var e = event.toElement || event.relatedTarget;
			if (e.parentNode == this || e == this) {
				return;
			}
			// console.log(event.target)
			// handle mouse event here!
	}

	//加入購物車
	var cartMsgTimer = null;
	$('.js-add-to-cart').on('click', function() {
		clearTimeout(cartMsgTimer);
		$('.message').slideDown();
		$('.message').attr('data-show', 'add-to-cart');
		
		cartMsgTimer = setTimeout(function() {$(".message").slideUp();}, 2000); 
	});
	
	// 我要預購
	$('.js-add-preorder').on('click', function() {
		clearTimeout(cartMsgTimer);
		$('.message').slideDown();
		$('.message').attr('data-show', 'add-preorder');
		
		cartMsgTimer = setTimeout(function() {$(".message").slideUp();}, 2000); 
	});

	// 貨到通知
	$('.js-delivery-notice').on('click', function() {
		clearTimeout(cartMsgTimer);

		if($(this).attr('data-subscr') == 'true') {
			$(this).attr('data-subscr', false);
			$(this).removeClass('btn-disabled').addClass('btn-default');
			$(this).find('span').text('貨到通知');
		} else {
			$(this).attr('data-subscr', true);
			$(this).addClass('btn-disabled').removeClass('btn-default');
			$('.message').slideDown();
			$('.message').attr('data-show', 'delivery-notice');
			$(this).find('span').text('已訂閱貨到通知');
		}
		
		cartMsgTimer = setTimeout(function() {$(".message").slideUp();}, 2000); 
	});

	// 價格對齊
	function pdPriceAlign() {
		if($('.card__box.vm-card__pd .card__box-info__price.is-sale').length) {
			var a = $('.card__box.vm-card__pd .card__box-info__price.is-sale').height();
			$('.card__box.vm-card__pd .card__box-info__price:not(.is-sale)').height(a);
		}
	}

	// -----------------------------------
	// IE height
	// -----------------------------------
	function mobileFullHeight() {
		var isIE = $("html").hasClass("ie");
		if (!isIE) {
		// 取得視窗高度，再乘以1%得到1vh的值
		var vh = window.innerHeight * 0.01;
		var vw = window.innerWidth * 0.01;
		// 把--vh的值設置到文件的根元素中
		document.documentElement.style.setProperty("--vh", vh + "px");
		document.documentElement.style.setProperty("--vw", vw + "px");
		}
	}

})(jQuery, window, document);

// Stop page scroll
function triggeHtmlStop(boolean) {
	if (typeof window.pageYOffset != "undefined") {
	  bodyTop = window.pageYOffset;
  
	} else if (typeof document.compatMode != "undefined"
			   && document.compatMode != "BackCompat") {
	  bodyTop = document.documentElement.scrollTop;
  
	} else if (typeof document.body != "undefined") {
	  bodyTop = document.body.scrollTop;
	}

	var $html = $('html');

	if (boolean) {
		// 打開modal
		$html.addClass('stop-scrolling');
		$html.attr('data-pos', bodyTop);
		$html.css('top', -bodyTop);
		$html.attr('data-scroll', false);
	} else {
		// 關閉modal
		$html.removeClass('stop-scrolling');
		window.scrollTo(0, $html.attr('data-pos'));
		$html.attr('data-scroll', true);
		document.documentElement.style.removeProperty('top');
	}
}

// -----------------------------------
// 為 swiper 的商品card add wish 加上樣式
// data-add-wish: 0 加入 , 1 未加入
// -----------------------------------
function setSwiperAddToWishActive() {
	var $target = '.js-addwish';

	$(document).on('click', $target +' .card__box-addwish', function(){
		var wrapClass = '.card__box';
		var thisCardNum = $(this).parents(wrapClass).attr('data-card');
		var thisWishStatue = $(this).parents(wrapClass).attr('data-add-wish');

		if(+thisWishStatue){
			if(thisCardNum) {
				$(this).parents('.js-addwish').find(wrapClass + '[data-card="'+ thisCardNum +'"]').attr('data-add-wish', 0);
			} else {
				$(this).parents(wrapClass).attr('data-add-wish', 0);
			}

		} else {
			if(thisCardNum) {
				$(this).parents('.js-addwish').find(wrapClass + '[data-card="'+ thisCardNum +'"]').attr('data-add-wish', 1);
			} else {
				$(this).parents(wrapClass).attr('data-add-wish', 1);
			}
		}
					
	});
}

// Check loadload bg
function checkIfDataBgIsNotLoad() {
	var $els =  document.querySelectorAll('.lazyloaded[data-bg]');
	if($els.length) {
		for (var i = 0; i < $els.length; i++) {
			if(!$els[i].style.backgroundImage) {
				var thisBg = $els[i].getAttribute('data-bg');
				$els[i].style.backgroundImage = thisBg;
			}
		}
	} 
}

// 轉成千分位
function toCurrency(num){
	num = num.replace(/,/g, "");

	var num = (num || 0).toString(), result = '';
    while (num.length > 3) {
        result = ',' + num.slice(-3) + result;
        num = num.slice(0, num.length - 3);
	}
	
    if (num) { result = num + result; }
    return result;
}

// OPT邏輯
function inputKey() {
	var inputKeyNumber = $('.form_otp_keynumber');
	var inputKeyNumberLength = inputKeyNumber.length; // 欄位數量
	var inputKeyNumberMaxLenth = parseInt(inputKeyNumber.eq(0).attr('maxlength')); // 輸入的最大字數(抓取第一個物件的maxlength)
	var numberRegex = /^([+-]?[1-9]\d*|0)$/; // Ref: https://stackoverflow.com/a/21505954

	/** 監測input輸入的內容、文字長度、轉換Focus到下一個*/
	inputKeyNumber.on('keyup', function (event) {
		var self = $(this);

		// 避免出現可用於數字計算的符號文字，如"e", "."，Ref: https://stackoverflow.com/a/4460628
		if (self.val().match(numberRegex) === null) {
			self.val(self.val().replace(numberRegex, ''));
		}

		if (self.val().length >= inputKeyNumberMaxLenth) {
			// 最多只能輸入maxlength個字
			// Ref: https://stackoverflow.com/a/8355013
			self.val(self.val().slice(0, inputKeyNumberMaxLenth));

			// Focus Next
			var currentIndex = self.index();
			if (currentIndex <= inputKeyNumberLength - 2) {
				self.next().focus();
				// inputKeyNumber.eq(currentIndex + 1).focus();
			} else if (currentIndex === inputKeyNumberLength - 1) {
				// 最後一項 => 移除Focus物件
				self.blur();
			}
		} else if (self.val().length < 1) {
			// 倒回前一個
			self.prev().focus();
		}
	});

	/** 如果有輸入值，選擇(Focus)<input>，則會全選<input>文字*/
	inputKeyNumber.on('focus', function () {
		if (this.value.length > 0) {
			// 因為type="number"沒有支援全選function(setSelectionRange)
			// 所以先換text選完再換回number
			// Ref: https://stackoverflow.com/a/40017467

			// 因為iOS切換type頁面會一直跳，所以iOS系統不做此功能
			var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
			if (!iOS) {
				this.type = 'text';
				this.setSelectionRange(0, this.value.length);
				this.type = 'number';
			}
		}
	});

	// inputKeyNumber.on('paste', function (e) {
	// 	var pasteTxt = e.originalEvent.clipboardData.getData('text');

	// 	if(numberRegex.test(pasteTxt)) {
	// 		var pasteArr = pasteTxt.split('')
	// 		console.log(inputKeyNumber[0])
	// 	}
	// });

	inputKeyNumber.each(function(index, item) {
		item.addEventListener('paste', handlePaste);
	});

	// Ref: stack overflow: https://stackoverflow.com/a/6804718/11240898
	// Ref: Demo: https://jsfiddle.net/swL8ftLs/12/
	function handlePaste(e) {	
		e.stopPropagation();
		e.preventDefault();
	
		// 取得貼上的資料
		var clipboardData = e.clipboardData || window.clipboardData;
		var pastedData = "";
		if (window.clipboardData && window.clipboardData.getData) { // IE
			// Ref: https://stackoverflow.com/a/30126108/11240898
			pastedData = window.clipboardData.getData('Text');
		} else if (e.clipboardData && e.clipboardData.getData) {
			pastedData = e.clipboardData.getData('text/plain');
		}
		pastedData = pastedData.replace(/\D/g, ''); // 取代掉非數字文字
	
		// 如果為空值 => 則不運作
		if (pastedData === '') { return }
	
		// 將貼上的文字轉為 Array
		var pasteArray = pastedData.split('');
	
		// 將貼上的文字塞入input
		for (var i = 0; i < inputKeyNumber.length; i++) {	
			inputKeyNumber.eq(i)[0].value = pasteArray[i] || '';
		}
	
		// 移除 Focus 狀態 (IE無作用)
		// 看是要貼上後 「送出」？或是 Focus 在送出按鈕，需自行客製
		e.target.blur();
	}
};

// -----------------------------------
// Set Cookie loading畫面一天只出現一次
// -----------------------------------
function setCookie(hour, chkname) {
	// 單位為一小時 hour
	var d_tomorrow = new Date().setHours(hour, 0, 0, 0),
		d_time = new Date(d_tomorrow);

	var expires = 'expires=' + d_time.toUTCString();
	document.cookie = chkname + ' ' + expires;
}

function getCookie(cname) {
	var name = cname + '=';
	var ca = document.cookie.split(';');

	for (var i = 0; i < ca.length; i++) {
		var c = ca[i].trim();

		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}

	return '';
}

function checkCookieAccept() {
	if (!getCookie('greennsafeWelcome').length) {
		
		// if($('body.path-index').length && $('#modal__ad-event').length) {
		if($('#modal__ad-event').length) {
			$('#modal__ad-event').foundation('open');
			$('html').attr('data-scroll', false);
			setCookie(24, 'greennsafeWelcome=welcome;');
		}
	}
}
